html {
  width: 100%; height: 100%;
  background-color: #333;
  background-image: url('/tumblr_mvdjoaxakn1rnwkn9o1_1280.jpg');
  background-size: cover;
  color: #e3e5e7;
}

body {
  font: normal 16px/160% 'Montserrat', sans-serif;
  margin: 0; padding: 0;
  width: 66%;
  margin: 0 auto;
  position: absolute;
  left: 50%; top: 50%;
  transform: translate(-50%, -60%);
  text-align: center;
}

h1 {
  font-weight: bold;
  margin-bottom: 1em;
}

h2 {
  color: #999;
  font-weight: normal;
  opacity: 0;
}

a {
  display: inline-block;
  padding: 0.2em 0.5em;
  text-decoration: none;
  color: white;
  transition: background-color 2s;
  background-color: transparent;
}

a:hover, .c1 a:hover, .c2 a:hover, .c3 a:hover, .c4 a:hover, .c5 a:hover {
  text-decoration: none;
  transition: background-color .42s;
}

.c1 a:hover {
  background-color: #CF5028;
}
.c2 a:hover {
  background-color: #709D9B;
}
.c3 a:hover {
  background-color: #89BCB4;
}
.c4 a:hover {
  background-color: #907149;
}
.c5 a:hover {
  background-color: #729D9E;
}


h1 span {
  font-weight: 100;
  font-style: italic;
}

p {
  font-weight: normal;
   margin-bottom: 0;
   margin-top: 0;
}

p em {
  font-style: normal;
  font-weight: bold;
  font-size: 75%;
  text-transform: uppercase;
  opacity: .66;
  margin-left: .4em;
}

p.spamoji em {
  opacity: 1;
}

p.mail {
  font-size: 85%;
}

@media (max-width: 400px) {
  p em { display: none; }
  p.spamoji em { display: block; }
}